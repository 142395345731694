import React from "react";
import Chart from "react-apexcharts";

const SubscriptionPieChart = ({ subscriptionData }) => {
  // Extracting labels and values
  const labels = Object.keys(subscriptionData);
  const values = Object.values(subscriptionData);

  // Chart options and series data
  const chartOptions = {
    chart: {
      type: "pie",
      height: 350,
    },
    title: {
      text: "Subscription Plans",
      align: "center",
    },
    labels: labels,
    colors: ["#008FFB", "#00E396", "#FF4560"], // You can customize the colors
    dataLabels: {
      enabled: true,
    },
  };

  const series = values;

  return (
    <div>
      <Chart options={chartOptions} series={series} type="pie" height={350} />
    </div>
  );
};

export default SubscriptionPieChart;
