import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import TopShellingProductChart from "../../components/dashboard/TopShellingProductChart";
import SubscriptionPieChart from "../../components/dashboard/SubscriptionPieChart";
import TopSellingProductOfferChart from "../../components/dashboard/TopSellingProductOfferChart";

function Dashboard({ subdomain }) {
  // const [dashboardData, setDashboardData] = useState({
  //   total_vendor_status: {
  //     Registered: 0,
  //     "Otp Verified": 0,
  //     "Document Uploaded": 0,
  //     Rejected: 0,
  //     Inactive: 0,
  //     Approved: 0,
  //     "Reuploaded Document": 0,
  //   },
  //   suscription: {
  //     Free: 0,
  //     Silver: 0,
  //     Gold: 0,
  //   },
  //   offers: {},
  // });

  // Function to map the object keys to readable titles
  const getTitle = (key) => {
    const titleMap = {
      total_products: "Products",
      total_vendors: "Vendors",
      total_buyers: "Buyers",
      total_active_products: "Active Products",
      total_inactive_products: "Inactive Products",
      total_available_products: "Available Products",
      total_out_of_stock_products: "Out of Stock Products",
      total_categories: "Categories",
      total_reports: "Reports",
      total_offers: "Offers",
    };
    return titleMap[key] || key;
  };

  // Function to map the object keys to icon classes
  const getIconClass = (key) => {
    const iconMap = {
      total_vendors: "icofont-business-man fs-3 color-light-blue",
      total_buyers: "icofont-users fs-3 color-light-green",
      total_products: "icofont-product fs-3 color-light-orange",
      total_active_products: "icofont-check fs-3 color-light-orange",
      total_inactive_products: "icofont-close fs-3 color-light-red",
      total_available_products: "icofont-check fs-3 color-light-green",
      total_out_of_stock_products: "icofont-out-of-stock fs-3 color-light-red",
      total_categories: "icofont-category fs-3 color-light-purple",
      total_reports: "icofont-file fs-3 color-light-blue",
      total_offers: "icofont-sale-discount fs-3 color-light-orange",
    };
    return iconMap[key] || "icofont-default";
  };

  // Function to convert the object to the desired array of objects
  const convertToArray = (data) => {
    return Object.keys(data).map((key) => ({
      result_id: key,
      title: getTitle(key),
      value: data[key].toString(),
      iconClass: getIconClass(key),
      cardBg: "bg-light",
    }));
  };

  const [totalList, setTotalList] = useState([]);
  const [topSellingProduct, setTopSellingProduct] = useState({});
  const [topSellingProductOffer, setTopSellingProductOffer] = useState({});
  const [subscriptionPieChart, setSubscriptionPieChart] = useState({});

  async function getDashboardData() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/${
          subdomain === "vendor" ? "vendor-" : ""
        }dashboard/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();
      const result = convertToArray(data.total);
      // setDashboardData(data);
      setTopSellingProduct(data?.category);
      setTopSellingProductOffer(data?.products);
      setSubscriptionPieChart(data?.suscription);
      setTotalList(result);
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div className="body d-flex py-3 mt-4">
      <div className="container-xxl">
        <div className="mt-1">
          <Row>
            <Col sm={12}>
              <div className="row g-1 g-sm-3 mb-3 row-deck">
                {totalList.map((d, i) => {
                  return (
                    <div
                      key={"todaydata" + i}
                      className="col-xl-4 col-lg-4 col-md-4 col-sm-6"
                    >
                      <div className="card">
                        <div className="card-body py-xl-4 py-3 d-flex flex-wrap align-items-center justify-content-between">
                          <div className="left-info">
                            <span className="text-muted">{d.title}</span>
                            <div>
                              <span className="fs-6 fw-bold me-2">
                                {d.value}
                              </span>
                            </div>
                          </div>
                          <div className="right-icon">
                            <i className={`${d.iconClass}`}></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>

        <div className="row">
          <div className="col-xl-6">
            {subdomain === "admin" && subscriptionPieChart && (
              <div className="row g-3 mb-3">
                <div className="col-xl-12">
                  <div className="card p-4">
                    <SubscriptionPieChart subscriptionData={subscriptionPieChart} />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-xl-6">
          {subdomain === "admin" && topSellingProduct && (
            <div className="row g-3 mb-3">
              <div className="col-xl-12">
                <TopShellingProductChart
                  data={topSellingProduct}
                  heading="Top Categories"
                />
              </div>
            </div>
          )}
          </div>

          <div className="col-xl-6">
          {subdomain === "vendor" &&
            Object.keys(topSellingProductOffer ?? {}).length > 0 && (
              <div className="row g-3 mb-3">
                <div className="col-xl-12">
                  <TopSellingProductOfferChart
                    data={topSellingProductOffer}
                    heading="Top Selling Products"
                  />
                </div>
              </div>
            )}
            </div>
          </div>
      </div>

      {/* <div className='commingSoon'>
                <p>
                    <img src="https://safeandsoundva.com/wp-content/uploads/2019/08/coming-soon-PNG.png" />
                    Coming Soon...
                </p>
            </div> */}
    </div>
  );
}
export default Dashboard;
