import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader1 from "../../components/common/PageHeader1";
import { toast } from "react-toastify";
import st from "./FAQ.module.scss";
import { useNavigate } from "react-router";
import { FilterComponent } from "../../components/common/FilterComponent";
import { MdOutlineRefresh } from "react-icons/md";

function UserReport() {
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [table_row, setTable_row] = useState([]);
  const [isChangeStatusModel, setIsChangeStatusModel] = useState(false);
  const [changeStatusTo, setChangeStatusTo] = useState({});
  const [user, setuser] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData") ?? {});
  const [table_row_open, setTable_row_open] = useState([]);
  const [table_row_resolved, setTable_row_resolved] = useState([]);

  // const userData = JSON.parse(localStorage.getItem("userData") ?? `{}`);

  const columns = () => {
    return [
      {
        name: " ID",
        selector: (row) => row.product.id,
        sortable: true,
        compact: true,
      },
      {
        name: "Product Image",
        selector: (row) => row?.product_images,
        sortable: true,
        compact: true,
        cell: (row) => {
          return (
            <>
              {row?.product?.product_images?.length > 0 ? (
                row?.product?.product_images?.slice(0, 1)?.map((item) => {
                  return (
                    <img
                      key={row.id}
                      className="avatar border"
                      src={item?.image_url}
                      alt="productImg"
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50px",
                      }}
                    />
                  );
                })
              ) : (
                <span>No Image</span>
              )}
            </>
          );
        },
      },
      {
        name: "Product",
        selector: (row) => (row?.product ? row?.product?.title : ""),
        sortable: true,
        compact: true,
      },
      {
        name: "Seller",
        selector: (row) =>
          row?.product?.user ? row?.product?.user?.email : "",
        sortable: true,
        compact: true,
        minWidth: "200px",
      },

      {
        name: "Report Count",
        selector: (row) => row?.reports,
        sortable: true,
        compact: true,
        minWidth: "150px",
        cell: (row) => (
          <>
            <span>{row?.reports ? row?.reports?.length : 0}</span>
          </>
        ),
      },
      // {
      //   name: "Status",
      //   selector: (row) => {},
      //   sortable: true,
      //   compact: true,
      //   minWidth: "150px",
      //   cell: (row) => (
      //     <>
      //       {row.status === "pending" && (
      //         <button
      //           type="button"
      //           className="btn btn-warning text-white"
      //           onClick={() => {
      //             setIsChangeStatusModel(true);
      //             setChangeStatusTo({
      //               id: row?.id,
      //               modelHeading: "Change Buyer Report Status",
      //               status: row.status === "pending" ? "resolved" : "",
      //               apiParameters: {
      //                 status: row.status === "pending" ? "resolved " : "",
      //               },
      //             });
      //           }}
      //         >
      //           Pending
      //         </button>
      //       )}
      //       {row.status === "resolved" && (
      //         <button type="button" className="btn btn-success text-white">
      //           Resolved
      //         </button>
      //       )}
      //     </>
      //   ),
      // },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        compact: true,
        cell: (row) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            {/* {userData?.is_vendor ? (
              <button
                onClick={() => {
                  // setIseditmodal(true);
                  reset({
                    ...row,
                  });
                  setValue("type", "edit");
                }}
                type="button"
                className="btn btn-outline-secondary"
              >
                <i className="icofont-edit text-success"></i>
              </button>
            ) : (
              <></>
            )} */}
            {/* <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button> */}
            <button
              type="button"
              onClick={() => {
                row["view"] = true;
                row["tab"] = active ? "resolved" : "pending";

                navigate("/product-report-details", { state: row });
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-eye-alt"></i>
            </button>
          </div>
        ),
      },
    ];
  };

  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/report-ASk/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("Issue deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getProductReportList();
    }
  };

  async function getProductReportList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/product_reports/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const data = await response.json();

      const openReports = data?.map((item) => {
        return {
          ...item,
          reports: item?.reports?.filter(
            (report) => report?.status === "pending"
          ),
        };
      });
      const resolvedReports = data?.map((item) => {
        return {
          ...item,
          reports: item?.reports?.filter(
            (report) => report?.status === "resolved"
          ),
        };
      });
      setTable_row_open(openReports.filter((item) => item?.reports.length));
      setTable_row_resolved(
        resolvedReports.filter((item) => item?.reports.length)
      );

      // let user = JSON?.parse(localStorage?.getItem("userData")) || {};
      // if (user && user?.is_superuser) {
      //   setTable_row(
      //     data.filter(
      //       (item) => item?.api_type === "report" && item?.user_type === "buyer"
      //     )
      //   );
      // }
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getProductReportList();
  }, []);

  const onStatusSubmit = async () => {
    try {
      const formData = new FormData();
      Object.entries(changeStatusTo.apiParameters)?.forEach(([key, value]) => {
        formData.append(key, value.trim());
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/report-ASk/${changeStatusTo?.id}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 200) {
        toast.success("Buyer report status updated successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsChangeStatusModel(false);
      getProductReportList();
    }
  };

  //Filtered Buyer List
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  const filteredItemsOpen = table_row_open.filter((item) => {
    return (
      item?.product?.title &&
      item?.product?.title.toLowerCase().includes(filterText.toLowerCase())
    );
  });
  const filteredItemsResolved = table_row_resolved.filter((item) => {
    return (
      item?.product?.title &&
      item?.product?.title.toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="body d-flex">
      <div className="container-xxl mt-3">
        <PageHeader1
          pagetitle="Product Reports"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                {/* {userData?.is_vendor ? (
                  <button
                    type="button"
                    onClick={() => {
                      setIsmodal(true);
                      setValue("type", "add");
                    }}
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add Issue
                  </button>
                ) : (
                  <></>
                )} */}
              </div>
            );
          }}
        />
        {userData && userData?.is_superuser ? (
          <div
            className={`d-flex align-items-center justify-content-between ${st.tabsTable}`}
          >
            <ul>
              {" "}
              <li
                className={`${!active ? "active" : ""}`}
                onClick={() => {
                  setActive(false);
                }}
              >
                Open
              </li>
              <li
                className={`${active ? "active" : ""}`}
                onClick={() => {
                  setActive(true);
                }}
              >
                Resolved
              </li>
            </ul>
            <ul>
              <li onClick={getProductReportList}>
                <MdOutlineRefresh />
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
        {!active ? (
          <div className="row clearfix g-3">
            <div className="col-sm-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div
                    id="myProjectTable_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <DataTable
                          columns={columns()}
                          defaultSortField="title"
                          pagination
                          selectableRows={false}
                          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                          highlightOnHover={true}
                          data={filteredItemsOpen}
                          subHeader
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeaderComponent={subHeaderComponentMemo}
                          persistTableHead
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row clearfix g-3">
            <div className="col-sm-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div
                    id="myProjectTable_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <DataTable
                          columns={columns()}
                          defaultSortField="title"
                          pagination
                          selectableRows={false}
                          className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                          highlightOnHover={true}
                          data={filteredItemsResolved}
                          subHeader
                          paginationResetDefaultPage={resetPaginationToggle}
                          subHeaderComponent={subHeaderComponentMemo}
                          persistTableHead
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Buyer Report
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete this buyer report ?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isChangeStatusModel}
        onHide={() => {
          setIsChangeStatusModel(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            {changeStatusTo?.modelHeading}
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to change the status to{" "}
                {changeStatusTo.status}?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsChangeStatusModel(false);
                    setChangeStatusTo({});
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onStatusSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default UserReport;
