import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader1 from "../../components/common/PageHeader1";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { validateEmail } from "../../components/common/helper";
import st from "./common.module.scss";
import { handleKeyDown } from "../../helper/validation";
import { useNavigate } from "react-router";
import { FilterComponent } from "../../components/common/FilterComponent";
import { IoChatboxSharp } from "react-icons/io5";
import CustomToolbar from "../../components/Mui/Filteration/CustomToolbar";
import { CustomPagination } from "../../components/Mui/Pagination/Pagination";
import useSubHeaderComponent from "../../components/Mui/Filteration/Filteration";
import _ from "lodash";
import { DataGrid } from "@mui/x-data-grid";

function AdminList() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const userData = JSON.parse(localStorage.getItem("userData"));

  const [table_row, setTable_row] = useState([]);
  const [ismodal, setIsmodal] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  const [user, setuser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRowCount, setTotalRowCount] = useState(0);

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });

  const columns = () => {
    return [
      {
        field: "id",
        headerName: "ID",
        sortable: true,
        width: 200,
      },
      {
        field: "full_name",
        headerName: "Full Name",
        renderCell: ({ row }) => (
          <>
            <span className="px-2">{row?.full_name}</span>
          </>
        ),
        sortable: true,
        compact: true,
        width: 300,
      },

      {
        field: "email",
        headerName: "Email",
        sortable: true,
        compact: true,
        width: 300,
      },
      {
        field: "created_at",
        headerName: "Registration Date",
        sortable: true,
        width: 300,
        valueGetter: (_, row) => row?.created_at?.split("T")[0],
      },
      {
        field: "action",
        headerName: "ACTION",
        sortable: true,
        width: 250,
        compact: true,
        renderCell: ({ row }) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
          </div>
        ),
      },
    ];
  };
  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("Admin deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getAdminList();
    }
  };

  async function getAdminList(search = "") {
    try {
      // setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/Admin-register/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
        // setIsLoading(false);
      } else {
        // setIsLoading(false);
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTable_row(data);
      setTotalRowCount(data.length);
      // ...
    } catch (error) {
      // setIsLoading(false);
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsLoading(false);
      reset();
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetProductList = useCallback(_.debounce(getAdminList, 300));

  useEffect(() => {
    debouncedGetProductList();
  }, []);

  const onSubmit = async ({ firstName, lastName, email, type, id }) => {
    try {
      const formData = new FormData();
      formData.append("full_name", firstName + " " + lastName);
      formData.append("email", email);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/Admin-register/${
          type === "add" ? "" : id + "/"
        }`,
        {
          method: type === "add" ? "POST" : "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        if (response.status === 201) {
          setTimeout(() => {
            toast.success("Admin user created successfully");
            setIsmodal(false);
          }, 500);
        }
        if (response.status === 200) {
          toast.success("Admin user updated successfully");
          setIseditmodal(false);
        }
        getAdminList();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  //Filtered Admin List
  // const [filterText, setFilterText] = React.useState("");
  // const [resetPaginationToggle, setResetPaginationToggle] =
  //   React.useState(false);
  // const filteredItems = table_row.filter((item) => {
  //   return (
  //     item?.full_name &&
  //     item?.full_name.toLowerCase().includes(filterText.toLowerCase())
  //   );
  // });

  // const subHeaderComponentMemo = React.useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };
  //   return (
  //     <FilterComponent
  //       onFilter={(e) => setFilterText(e.target.value)}
  //       onClear={handleClear}
  //       filterText={filterText}
  //     />
  //   );
  // }, [filterText, resetPaginationToggle]);

  // Filtering Product based on Product Title
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // Debounce the filtering function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFilter = useCallback(
    _.debounce((input) => {
      getAdminList(input.toLowerCase());
    }, 1000), // Adjust the delay as needed
    []
  );

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilterText(value.toLowerCase());
    debouncedFilter(value);
  };

  const filteredItems = useMemo(() => {
    return table_row.filter((item) => {
      return (
        item?.full_name &&
        item?.full_name.toLowerCase().includes(filterText.toLowerCase())
      );
    });
  }, [filterText, table_row]);

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
      getAdminList("");
    }
  };

  const subHeaderComponentMemo = useSubHeaderComponent({
    filterText,
    resetPaginationToggle,
    handleFilterChange,
    handleClear,
  });

  useEffect(() => {
    getAdminList(filterText);
  }, [paginationModel]);

  return (
    <div className="body d-flex">
      <div className="container-xxl">
        <PageHeader1
          pagetitle="Admin Information"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                <button
                  type="button"
                  onClick={() => {
                    setIsmodal(true);
                    reset();
                    setValue("type", "add");
                  }}
                  className="btn btn-primary btn-set-task w-sm-100"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Add Admin
                </button>
              </div>
            );
          }}
        />
        <div className="row clearfix g-3">
          <div className="col-sm-12 mt-3">
            <div className="card ">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <DataGrid
                        rows={filteredItems}
                        columns={columns()}
                        loading={isLoading}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableToolbarButton={true}
                        paginationMode="server"
                        pageSizeOptions={[10, 20, 50, 100]}
                        paginationModel={paginationModel}
                        rowCount={totalRowCount}
                        onPaginationModelChange={setPaginationModel}
                        slots={{
                          toolbar: CustomToolbar,
                          pagination: CustomPagination,
                        }}
                        slotProps={{
                          toolbar: {
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                            subHeaderComponentMemo, // Pass the memoized component to the toolbar
                          },
                        }}
                        sx={{
                          border: "none", // Remove the border of the DataGrid
                          "& .MuiDataGrid-root": {
                            border: "none",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={ismodal}
        onHide={() => {
          setIsmodal(false);
          reset({});
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Add Admin
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="First Name"
                    {...register("firstName", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.firstName && (
                    <span className="text-danger">First Name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    id="item"
                    {...register("lastName", { required: true })}
                    onKeyDown={handleKeyDown} // Add event handler for keydown
                  />
                  {errors.firstName && (
                    <span className="text-danger">Last Name is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="item"
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                    defaultValue=""
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className="text-danger">Email is required.</span>
                  )}
                  {errors.email && errors.email.type === "validate" && (
                    <span className="text-danger">
                      Please enter a valid email address.
                    </span>
                  )}
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsmodal(false);
                    reset();
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Admin
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete ?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default AdminList;
