import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { validateEmail, validatePhone } from "../common/helper";
import { HiPlusSm } from "react-icons/hi";
import st from "../../style.module.scss";
import { FaFileDownload } from "react-icons/fa";
import {
  handleKeyDown,
  handleKeyDownWithNumbers,
} from "../../helper/validation";
import { checkUrlType } from "../../helper/imagePathHandler";
import ImageModel from "../common/ImageModel";

function DocumentVerification() {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();
  const [isImageModal, setIsImageModal] = useState(false);
  const [imageInPopup, setImageInPopup] = useState("");
  const [image, setImage] = useState({ value: "", status: false });
  const [docImage1, setdocImage1] = useState({ value: "", status: false });
  const [docImage2, setdocImage2] = useState({ value: "", status: false });
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    setImage({ value: location?.state?.data?.image, status: false });
    setdocImage1({ value: location?.state?.data?.doc1, status: false });
    setdocImage2({ value: location?.state?.data?.doc2, status: false });
    reset({
      full_name: location?.state?.data?.full_name,
      shop_name: location?.state?.data?.shop_name,
      phone: location?.state?.data?.phone,
      email: location?.state?.data?.email,
      address: location?.state?.data?.address,
      image: location?.state?.data?.image,
      doc1: location?.state?.data?.doc1,
      doc2: location?.state?.data?.doc2,
    });
  }, []);

  const onSubmit = async ({
    full_name,
    shop_name,
    // phone,
    // email,
    address,
    image,
    doc1,
    doc2,
  }) => {
    setIsSubmit(true);
    try {
      let apiId = location?.state?.data?.id;

      const formData = new FormData();
      full_name && formData.append("full_name", full_name);
      shop_name && formData.append("shop_name", shop_name);
      address && formData.append("address", address);
      if (doc1 && doc1[0] !== "h") {
        formData.append("doc1", doc1[0]);
      }
      if (doc2 && doc2.length && doc2[0] !== "h") {
        formData.append("doc2", doc2[0]);
      }

      if (image && image[0] !== "h") {
        formData.append("image", image[0]);
      }
      formData.append("status", "reuploaded document");

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${
          apiId + "/"
        }`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      // const data = await response.json();

      if (response.status === 200) {
        // const responseData = await response.json();
        // localStorage.setItem("userData", JSON.stringify(responseData));
        toast.success("Please wait while the admin verifies your document.");
        // selectSubscription();
        setTimeout(() => {
          navigate("/sign-in/", { replace: true });
        }, 1000);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsSubmit(false);
    }
  };

  // const selectSubscription = async () => {
  //   try {
  //     // const user = JSON.parse(localStorage.getItem("userData"))?.id;
  //     const formData = new FormData();
  //     // formData.append("duration", 12); // Assuming data.duration corresponds to duration="1"
  //     formData.append("user", location?.state?.data?.id); // Assuming data.user corresponds to user="119"
  //     formData.append("plan", 1); // Assuming data.plan corresponds to plan="1"

  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASEURL}/api/subscriptions/`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //         body: formData,
  //       }
  //     );

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       if (response.status === 201) {
  //         setTimeout(() => {
  //           navigate("/sign-in/", { replace: true });
  //         }, 1000);
  //       }
  //       // getHelpSupportList(); // You may replace this with the appropriate function to update your UI
  //     } else {
  //       const data = await response.json();
  //       console.error("Failed to submit form");
  //     }
  //   } catch (error) {
  //     console.error(error, "Error submitting form:");
  //   }
  // };

  const [isUploading, setIsUploading] = useState({
    doc1: false,
    doc2: false,
    doc3: false,
    doc4: false,
  });
  const [uploadProgress, setUploadProgress] = useState({
    doc1: 0,
    doc2: 0,
    doc3: 0,
    doc4: 0,
  });
  const [fileName, setFileName] = useState({
    doc1: "",
    doc2: "",
    doc3: "",
    doc4: "",
  });
  const handleImageChange = (e, type = "image") => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    if (type === "image") {
      setImage({ value: newImage, status: true });
    }
    if (type === "doc1") {
      setdocImage1({ value: newImage, status: true });
    }
    if (type === "doc2") {
      setdocImage2({ value: newImage, status: true });
    }
  };

  const handleFileChange = (event, doc) => {
    const file = event.target.files[0];
    if (file.type.startsWith("image/")) {
      handleImageChange(event, doc);
    } else {
      setFileName((prevFileNames) => ({ ...prevFileNames, [doc]: file.name }));
      setIsUploading((prevIsUploading) => ({
        ...prevIsUploading,
        [doc]: true,
      }));

      if (doc === "doc1") {
        setdocImage1({ value: "", status: false });
      }

      if (doc === "doc2") {
        setdocImage2({ value: "", status: false });
      }

      // Simulate file upload progress
      const uploadInterval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          const newProgress = prevProgress[doc] + 10;
          if (newProgress >= 100) {
            clearInterval(uploadInterval);
            setIsUploading((prevIsUploading) => ({
              ...prevIsUploading,
              [doc]: false,
            }));
          }
          return { ...prevProgress, [doc]: newProgress };
        });
      }, 500); // Simulate upload progress every 500ms
    }
  };

  const onButtonClick = () => {
    const pdfUrl = "Sample.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "document.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function onhide() {
    setIsImageModal(false);
  }

  return (
    <>
      <div className="col-lg-6 d-flex justify-content-center">
        <div className="w-100 p-3 p-md-5 card border-0 shadow-sm">
          <form
            className="row g-1 p-3 p-md-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-12 text-center mb-5">
              <h1>Documents Verification</h1>
            </div>
            <div className="col-6">
              <div className="mb-2">
                <label className="form-label">Owner name</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Owner name"
                  {...register("full_name", { required: true })}
                  onKeyDown={handleKeyDown} // Add event handler for keydown
                />
                {errors.full_name && (
                  <span className="text-danger">Owner Name is required</span>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="mb-2">
                <label className="form-label"> Shop name</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Shop name"
                  {...register("shop_name", { required: true })}
                  onKeyDown={handleKeyDownWithNumbers} // Add event handler for keydown
                />
                {errors.shop_name && (
                  <span className="text-danger">Shop name is required</span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">Email address</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  placeholder="Email address"
                  {...register("email", {
                    required: true,
                    disabled: true,
                    validate: validateEmail,
                  })}
                  defaultValue=""
                />
                {errors.email && errors.email.type === "required" && (
                  <span className="text-danger">
                    Email address is required.
                  </span>
                )}
                {errors.email && errors.email.type === "validate" && (
                  <span className="text-danger">
                    Please enter a valid email address.
                  </span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">Phone number</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Phone number"
                  {...register("phone", {
                    required: true,
                    validate: validatePhone,
                  })}
                  defaultValue=""
                />
                {errors.phone && errors.phone.type === "required" && (
                  <span className="text-danger">Phone number is required.</span>
                )}
                {errors.phone && errors.phone.type === "validate" && (
                  <span className="text-danger">
                    Please enter a valid phone number.
                  </span>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">Shop address</label>
                <textarea
                  className="form-control form-control-lg"
                  placeholder="shop address"
                  {...register("address", { required: true })}
                >
                  {" "}
                </textarea>
                {errors.address && (
                  <span className="text-danger">Shop address is required</span>
                )}
              </div>
            </div>

            <div className="col-12">
              <div className="mb-2">
                <label className="form-label">
                  Shop Image <span className="text-danger">*</span>(Only images
                  are allowed)
                </label>
                <div className="d-flex gap-3">
                  {image && (
                    <div className={`${st.dummyImage}`}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={image?.value}
                        alt={`uploaded `}
                        onClick={() => {
                          setIsImageModal(true);
                          setImageInPopup(image?.value);
                        }}
                      />
                    </div>
                  )}
                  <div className={`${st.dummyImage}`}>
                    <HiPlusSm
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    />
                    <input
                      type="file"
                      id="fileInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      {...register("image", {
                        required: image?.status,
                        onChange: (e) => handleImageChange(e),
                      })}
                    />
                  </div>
                  {errors.image && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="mb-2">
                <label className="form-label">
                  Upload citizen ID (NIDA)
                  <span className="text-danger">*</span>(Only images or PDFs are
                  allowed)
                </label>
                <div className="uploadImage">
                  {checkUrlType(docImage1?.value) === "Image" && (
                    <div className={`${st.dummyImage}`}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={docImage1?.value}
                        alt={`uploaded `}
                        onClick={() => {
                          setIsImageModal(true);
                          setImageInPopup(docImage1?.value);
                        }}
                      />
                    </div>
                  )}
                  {checkUrlType(watch("doc1")) === "PDF" && (
                    <a
                      href={watch("doc1")}
                      className="btn"
                      target="_blank"
                      rel="noreferrer"
                      onClick={onButtonClick}
                    >
                      <span>
                        <FaFileDownload />
                      </span>
                    </a>
                  )}

                  <button className="btn">
                    <input
                      type="file"
                      className="form-control form-control-lg"
                      accept="image/*,.pdf"
                      {...register("doc1", {
                        required: docImage1.status,
                        onChange: (e) => {
                          handleFileChange(e, "doc1");
                        },
                      })}
                    />
                    +
                  </button>
                  {isUploading.doc1 && (
                    <div className="imageName w-100 pt-3">
                      <div className="progress-container">
                        <progress
                          className="progressBar"
                          value={uploadProgress.doc1}
                          max="100"
                        ></progress>
                        <span className="progress-value">
                          {uploadProgress.doc1}%
                        </span>
                      </div>
                    </div>
                  )}
                  {!isUploading.doc1 && fileName.doc1 && (
                    <div className="imageName w-100">
                      <input
                        type="text"
                        className="form-control"
                        value={fileName.doc1}
                        readOnly
                      />
                    </div>
                  )}
                </div>
                {errors.doc1 && (
                  <span className="text-danger">This field is required</span>
                )}
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="mb-2">
                <label className="form-label">
                  Upload business registration certificate (BRELA) (Only images
                  or PDFs are allowed)
                </label>
                <div className="uploadImage">
                  {checkUrlType(docImage2?.value) === "Image" && (
                    <div className={`${st.dummyImage}`}>
                      <img
                        style={{ cursor: "pointer" }}
                        src={docImage2?.value}
                        alt={`uploaded `}
                        onClick={() => {
                          setIsImageModal(true);
                          setImageInPopup(docImage2?.value);
                        }}
                      />
                    </div>
                  )}
                  {checkUrlType(watch("doc2")) === "PDF" && (
                    <a
                      href={watch("doc2")}
                      className="btn"
                      target="_blank"
                      rel="noreferrer"
                      onClick={onButtonClick}
                    >
                      <span>
                        <FaFileDownload />
                      </span>
                    </a>
                  )}
                  <button className="btn">
                    <input
                      type="file"
                      className="form-control form-control-lg"
                      accept="image/*,.pdf"
                      {...register("doc2", {
                        required: false,
                        onChange: (e) => {
                          handleFileChange(e, "doc2");
                        },
                      })}
                    />
                    +
                  </button>
                  {isUploading.doc2 && (
                    <div className="imageName w-100 pt-3">
                      <div className="progress-container">
                        <progress
                          className="progressBar"
                          value={uploadProgress.doc2}
                          max="100"
                        ></progress>
                        <span className="progress-value">
                          {uploadProgress.doc2}%
                        </span>
                      </div>
                    </div>
                  )}
                  {!isUploading.doc2 && fileName.doc2 && (
                    <div className="imageName w-100">
                      <input
                        type="text"
                        className="form-control"
                        value={fileName.doc2}
                        readOnly
                      />
                    </div>
                  )}
                </div>
                {/* {errors.doc2 && (
                      <span className="text-danger">This field is required</span>
                    )} */}
              </div>
            </div>

            <div className="col-12 text-center mt-4">
              <Button
                type="submit"
                className="btn btn-lg btn-block btn-light lift text-uppercase w-100 p-3"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>

      <ImageModel
        isImageModal={isImageModal}
        image={imageInPopup}
        onhide={onhide}
      />
    </>
  );
}
export default DocumentVerification;
