import React, { useState } from "react";

const ReadMore = ({ children, maxCharacterCount }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const text = children;

  // Check if the text length exceeds the maxCharacterCount
  const isLongText = text.length > maxCharacterCount;

  return (
    <div>
      {isLongText ? (
        <>
          {isExpanded ? text : `${text.substring(0, maxCharacterCount)}...`}
          <span
            onClick={toggleReadMore}
            style={{ color: "blue", cursor: "pointer" }}
          >
            {isExpanded ? " Read Less" : " Read More"}
          </span>
        </>
      ) : (
        text
      )}
    </div>
  );
};

export default ReadMore;
