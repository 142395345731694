import { useMemo } from "react";
import { FilterComponent } from "../../common/FilterComponent";

const useSubHeaderComponent = ({
  filterText,
  resetPaginationToggle,
  handleFilterChange,
  handleClear,
}) => {
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FilterComponent
        onFilter={handleFilterChange}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return subHeaderComponentMemo;
};

export default useSubHeaderComponent;
