import React from "react";
import { FaStar } from "react-icons/fa";

const StarRating = ({ rating }) => {
  // Create an array with the number of stars corresponding to the rating
  const stars = Array.from({ length: 5 }, (_, index) => {
    return (
      <FaStar key={index} color={index < rating ? "#ffc107" : "#e4e5e9"} />
    );
  });

  return <div>{stars}</div>;
};

export default StarRating;
