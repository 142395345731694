import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader1 from "../../components/common/PageHeader1";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import st from "./FAQ.module.scss";
import { FilterComponent } from "../../components/common/FilterComponent";

function FAQ() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm();
  const [isDeletemodal, setIsDeletemodal] = useState(false);

  const [table_row, setTable_row] = useState([]);
  const [ismodal, setIsmodal] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [user, setuser] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData") ?? {});

  const columns = () => {
    return [
      {
        name: " ID",
        selector: (row) => row.id,
        sortable: true,
        compact: true,
      },
      {
        name: "Question",
        selector: (row) => row?.question,
        sortable: true,
        compact: true,
      },
      {
        name: "Answer",
        selector: (row) => row?.answer,
        sortable: true,
        compact: true,
        minWidth: "200px",
      },

      {
        name: "To",
        selector: (row) => row?.type,
        sortable: true,
        compact: true,
        minWidth: "150px",
      },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        compact: true,
        cell: (row) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            <button
              onClick={() => {
                setIseditmodal(true);
                reset({
                  ...row,
                  faqtype: { label: row?.type, value: row?.type },
                });
                setValue("type", "edit");
              }}
              type="button"
              className="btn btn-outline-secondary"
            >
              <i className="icofont-edit text-success"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
          </div>
        ),
      },
    ];
  };

  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/FAQ-ASk/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("FAQ deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getFAQList();
    }
  };

  async function getFAQList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/FAQ-ASk/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      setTable_row(data);
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      reset();
    }
  }

  useEffect(() => {
    getFAQList();
  }, []);

  const onSubmit = async ({ question, answer, id, type, faqtype }) => {
    try {
      const formData = new FormData();
      formData.append("question", question);
      formData.append("type", faqtype.value);
      formData.append("answer", answer);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/FAQ-ASk/${
          type === "add" ? "" : id + "/"
        }`,
        {
          method: type === "add" ? "POST" : "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        if (response.status === 201) {
          setTimeout(() => {
            toast.success("FAQ created successfully");
            setIsmodal(false);
          }, 500);
        }
        if (response.status === 200) {
          toast.success("FAQ updated successfully");
          setIseditmodal(false);
        }
        getFAQList();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    }
  };

  //Filtered FAQ List
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = table_row.filter((item) => {
    return (
      item?.question &&
      item?.question.toLowerCase().includes(filterText.toLowerCase())
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="body d-flex">
      <div className="container-xxl mt-3">
        <PageHeader1
          pagetitle="FAQ Information"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                {userData?.is_superuser ? (
                  <button
                    type="button"
                    onClick={() => {
                      setIsmodal(true);
                      setValue("type", "add");
                    }}
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add FAQ
                  </button>
                ) : (
                  <></>
                )}
              </div>
            );
          }}
        />
        <div className="row clearfix g-3">
          <div className="col-sm-12 mb-0">
            <div className="card">
              <div className="card-body">
                <div
                  id="myProjectTable_wrapper"
                  className="dataTables_wrapper dt-bootstrap5 no-footer"
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <DataTable
                        columns={columns()}
                        defaultSortField="title"
                        pagination
                        selectableRows={false}
                        className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                        highlightOnHover={true}
                        data={filteredItems}
                        subHeader
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={iseditmodal}
        onHide={() => {
          setIseditmodal(false);
          reset({});
        }}
        className=""
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expeditLabel">
            {" "}
            Edit FAQ
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Question
                  </label>
                  <input
                    type="text"
                    placeholder="Question"
                    className="form-control"
                    id="item"
                    {...register("question", { required: true })}
                  />
                  {errors.question && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Answer
                  </label>
                  <input
                    type="text"
                    placeholder="Answer"
                    className="form-control"
                    id="item"
                    {...register("answer", { required: true })}
                  />
                  {errors.answer && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Type
                  </label>
                  <Controller
                    name="faqtype"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isSearchable={false}
                        className="react-dropdown"
                        classNamePrefix="dropdown"
                        options={[
                          { label: "Buyer", value: "buyer" },
                          { label: "Seller", value: "seller" },
                          { label: "Both", value: "both" },
                        ]}
                        onChange={(event) => {
                          field.onChange(event);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIseditmodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={ismodal}
        onHide={() => {
          setIsmodal(false);
          reset({});
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Add FAQ
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Question
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Question "
                    id="item"
                    {...register("question", { required: true })}
                  />
                  {errors.question && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Answer
                  </label>
                  <input
                    type="text"
                    placeholder="Answer"
                    className="form-control"
                    id="item"
                    {...register("answer", { required: true })}
                  />
                  {errors.answer && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="item" className="form-label">
                    Type
                  </label>
                  <Controller
                    name="faqtype"
                    control={control}
                    rules={{
                      required: "This field is required",
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isSearchable={false}
                        className="react-dropdown"
                        classNamePrefix="dropdown"
                        options={[
                          { label: "Buyer", value: "buyer" },
                          { label: "Seller", value: "seller" },
                          { label: "Both", value: "both" },
                        ]}
                        onChange={(event) => {
                          field.onChange(event);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsmodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
          reset({});
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete FAQ Item
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete ?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default FAQ;
