import React from "react";
import ImageSrc from "../../assets/images/mobileimg.png";
import logo from "../../assets/images/e360MartLogo.png";

function AuthLeft() {
  return (
    <div className="col-lg-6 d-none d-lg-flex justify-content-center">
      <div>
        <div className="text-center mb-3">
          {/* <i className="bi bi-bag-check-fill  text-primary" style={{ fontSize: 90 }}></i> */}
          <img src={logo} style={{ width: "260px" }} />
        </div>
        <div className="mb-5">
          <h2 className="color-900 text-center">
            The Only Connection You Need 24/7!
          </h2>
        </div>
        <div className=" m-auto" style={{ width: "40%" }}>
          <img className="w-100" src={ImageSrc} alt="login-img" />
        </div>
      </div>
    </div>
  );
}
export default AuthLeft;
