import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/common/Header";

function MainIndex(props) {
  const { activekey } = props;
  return (
    <div className="main px-lg-4 px-md-4">
      {activekey === "/chat" ? "" : <Header />}
      <div className="body d-flex pb-3">
        <Outlet />
      </div>
    </div>
  );
}
export default MainIndex;
