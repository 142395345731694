import React, { useState, useEffect } from "react";
import st from "../../style.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { handleKeyDown } from "../../helper/validation";
import { HiPlusSm } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router";
import ImageModel from "../../components/common/ImageModel";

function BuyerDetails() {
  let values = {};
  const userData = JSON.parse(localStorage.getItem("userData") ?? {});

  const [isImageModal, setIsImageModal] = useState(false);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  if (location?.state) {
    values = {
      full_name: location?.state?.full_name,
      phone: location?.state?.phone,
      email: location?.state?.email,
      address: location?.state?.address,
      apartment_number: location?.state?.apartment_number,
      street_name: location?.state?.street_name,
      city: location?.state?.city,
      state: location?.state?.state,
      zip_code: location?.state?.zip_code,
      image: location?.state?.image,
    };
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ values });

  const [isSubmit, setIsSubmit] = useState(true);

  useEffect(() => {}, []);

  const onSubmit = async ({
    full_name,
    shop_name,
    phone,
    email,
    address,
    image,
    apartment_number,
    street_name,
    city,
    state,
    zip_code,
  }) => {
    setIsSubmit(true);
    try {
      const formData = new FormData();
      formData.append("full_name", full_name);
      formData.append("shop_name", shop_name);
      formData.append("address", address);

      if (image && typeof image !== "string") {
        formData.append("image", image[0]);
      }
      formData.append(
        "address",
        `${apartment_number} ${street_name} ${city} ${state} ${zip_code}`
      );
      formData.append("apartment_number", apartment_number);
      formData.append("street_name", street_name);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("zip_code", zip_code);

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/login_signup/user-retrieve/${
          userData?.id + "/"
        }`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      // const data = await response.json();

      if (response.status === 200) {
        const responseData = await response.json();
        localStorage.setItem("userData", JSON.stringify(responseData));
        toast.success("Profile updated successfully");
        setTimeout(() => {
          navigate("/buyer-list");
        }, 500);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsSubmit(false);
    }
  };

  const handleImageChange = (e) => {
    const newImage = URL.createObjectURL(e.target.files[0]);
    setImage(newImage);
  };

  function onhide() {
    setIsImageModal(false);
  }

  return (
    <>
      <section className={`marginTopMobile30 ${st.pageWrapper} `}>
        <div className={`${st.pageWrapperInside}`}>
          <Card style={{ padding: "45px 0px 20px" }}>
            <Card.Body>
              <div className="deadline-form">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row g-3 mb-3">
                    <Col md={12} className={`mt-0`}>
                      <h3 className="fw-bold mb-0">Buyer Profile Details</h3>
                    </Col>

                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Full Name</label>
                      <input
                        type="text"
                        {...register("full_name", { disabled: true })}
                        className="form-control"
                        placeholder="Full name"
                        onKeyDown={handleKeyDown} // Add event handler for keydown
                      />
                      {errors.full_name && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Col>
                    <Col md={6} className={`${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        Email address
                      </label>
                      <input
                        type="text"
                        {...register("email", { disabled: true })}
                        className="form-control"
                        placeholder="Email address"
                      />
                      {/* {errors.email && (
                      <span style={{ color: "red" }}>
                        This field is required
                      </span>
                    )} */}
                    </Col>
                  </div>

                  <Row>
                    <Col md={6} className={`mt-3 ${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Phone number</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("phone", { disabled: true })}
                        placeholder="Phone number"
                      />
                    </Col>
                    <Col md={6} className={`mt-3 ${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>
                        Apartment number & flat number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Apartment number & flat number"
                        {...register("apartment_number", { disabled: true })}
                      />
                      {errors.apartment_number && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Col>
                    <Col md={6} className={`mt-3 ${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Street name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Street name"
                        {...register("street_name", { disabled: true })}
                      />
                      {errors.street_name && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Col>
                    <Col md={6} className={`mt-3 ${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>City</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        {...register("city", { disabled: true })}
                      />
                      {errors.city && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Col>
                    <Col md={6} className={`mt-3 ${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>State</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        {...register("state", { disabled: true })}
                      />
                      {errors.state && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Col>
                    <Col md={6} className={`mt-3 ${st.formBox}`}>
                      <label className={`${st.labelTitle}`}>Zip code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Zip code"
                        {...register("zip_code", { disabled: true })}
                      />
                      {errors.zip_code && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Col>
                    <div className="col-6 mt-5">
                      <div className="mb-2">
                        <label className="form-label">Profile Image</label>
                        <div className="d-flex gap-3">
                          {image && (
                            <div
                              className={`${st.dummyImage}`}
                              onClick={() => {
                                setIsImageModal(true);
                              }}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                src={image ?? location?.state?.image}
                                alt={`uploaded `}
                              />
                            </div>
                          )}
                          <div className={`${st.dummyImage}`}>
                            <HiPlusSm
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                            />
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              {...register("image", {
                                onChange: (e) => handleImageChange(e),
                              })}
                            />
                          </div>
                          {errors.image && (
                            <span className="text-danger">
                              Profile image is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>

                  {/* <div className={`mt-5 ${st.buttonBottom}`}>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmit}
                    >
                      Save
                    </button>
                  </div> */}
                </form>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      <ImageModel isImageModal={isImageModal} image={image} onhide={onhide} />
    </>
  );
}
export default BuyerDetails;
