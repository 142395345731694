import React from "react";
import TimeComponent from "../../common/TimeComponent";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

function ChatTab({ buyerList, search, checkMediaQuery }) {
  const navigate = useNavigate();
  const location = useLocation();
  const checkMediaQueryStatus = useMediaQuery("(max-width:991px)");

  return (
    <>
      <ul className="list-unstyled list-group list-group-custom list-group-flush mb-0">
        {buyerList.filter((item) =>
          item?.name?.toLowerCase()?.includes(search.toLowerCase())
        ).length > 0 ? (
          buyerList
            .filter((item) =>
              item?.name?.toLowerCase()?.includes(search?.toLowerCase())
            )
            .map((d, i) => {
              return (
                <li
                  key={"s" + i}
                  className="list-group-item px-md-4 py-3 py-md-4 cursor-pointer"
                  onClick={() => {
                    if (checkMediaQueryStatus) {
                      checkMediaQuery(true);
                      navigate(`/chat/${d.sender_id}/${d.receiver_id}`, {
                        state: {
                          communication: d,
                          buyer: location?.state?.buyer,
                        },
                      });
                    } else {
                      navigate(`/chat/${d.sender_id}/${d.receiver_id}`, {
                        state: {
                          communication: d,
                          buyer: location?.state?.buyer,
                        },
                      });
                    }
                  }}
                >
                  <div className="d-flex">
                    {/* <img className="avatar rounded" src={d.image} alt="" /> */}
                    <img
                      className="avatar rounded"
                      src={`${
                        d?.image
                          ? d?.image
                          : "https://tse1.mm.bing.net/th?id=OIP.qocnTczJTRMg-ZrYcwS4zAHaHa&pid=Api&P=0&h=180"
                      }`}
                      alt=""
                    />
                    <div className="flex-fill ms-3 text-truncate">
                      <h6 className="d-flex justify-content-between mb-1">
                        <span
                          className="text-truncate"
                          style={{ maxWidth: "180px" }}
                        >
                          {d.name}
                        </span>{" "}
                        <small className="msg-time">
                          <TimeComponent dateString={d.date_time} />
                        </small>
                      </h6>
                      <h6 className="d-flex justify-content-between mb-0">
                        <span className="text-muted small text-truncate">
                          {d.message}
                        </span>
                        {d.messagesCounter ? (
                          <span
                            className="badge badge-pill badge-primary"
                            style={{ backgroundColor: "#7258f1" }}
                          >
                            {d.messagesCounter}
                          </span>
                        ) : null}
                      </h6>
                    </div>
                  </div>
                </li>
              );
            })
        ) : (
          <li>No data found</li>
        )}
      </ul>
    </>
  );
}

export default ChatTab;
