import React, { useEffect, useState } from "react";
import OculusVR from "../../components/Products/ProductDetail/OculusVR";
import PageHeader1 from "../../components/common/PageHeader1";
import st from "./DynamicForm.module.scss";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router";
import { FilterComponent } from "../../components/common/FilterComponent";

function ProductOfferDetails() {
  const location = useLocation();

  const columns = () => {
    return [
      {
        name: "Full Name",
        selector: (row) => row.name,
        cell: (row) => <span>{row?.buyer?.full_name}</span>,
        sortable: true,
        minWidth: "200px",
      },
      {
        name: "Email",
        selector: (row) => row.buyer,
        cell: (row) => <span>{row?.buyer?.email}</span>,
        sortable: true,
      },
      {
        name: "Phone",
        selector: (row) => row.buyer,
        cell: (row) => <span>{row?.buyer?.phone}</span>,
        sortable: true,
      },
      {
        name: "Negotation Price",
        selector: (row) => row.query,
        cell: (row) => <span>{row?.negotiation_price}</span>,
        sortable: true,
      },
    ];
  };
  const [table_row, setTable_row] = useState([]);
  useEffect(() => {
    setTable_row(location?.state?.offers);
  }, []);

  // Filtering Product based on Title
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = table_row.filter((item) => {
    return (
      item?.buyer?.full_name &&
      item?.buyer?.full_name.toLowerCase().includes(filterText.toLowerCase())
    );
  });
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="container-xxl">
      <PageHeader1 pagetitle="Products Detail" />
      <div className="row g-3 mb-3">
        <OculusVR product={location?.state?.product} />

        <div className="col-md-12">
          <div className={`${st.venderProfile}`}>
            <h3 className="fw-bold mb-0">Offers</h3>
            <DataTable
              columns={columns()}
              defaultSortField="Full Name"
              pagination
              selectableRows={false}
              className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
              highlightOnHover={true}
              data={filteredItems}
              subHeader
              paginationResetDefaultPage={resetPaginationToggle}
              subHeaderComponent={subHeaderComponentMemo}
              persistTableHead
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductOfferDetails;
