import React from "react";
import AuthLeft from "../components/Auth/AuthLeft";
import { Outlet } from "react-router-dom";
import logo from "../assets/images/e360MartLogo.png";

function AuthIndex() {
  return (
    <div className="main p-2 py-3 p-xl-5 ">
      <div className="body d-flex p-0 p-xl-5">
        <div className="container-xxl">
          <div className="row g-0 align-items-center">
            <AuthLeft />
            <div className="text-center mb-3 d-block d-lg-none d-xl-none mb-4">
              <img src={logo} style={{ width: "170px" }} />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthIndex;
