import React, { useState, useEffect, Fragment } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PageHeader1 from "../../components/common/PageHeader1";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import st from "./FAQ.module.scss";
import { MdOutlineRefresh } from "react-icons/md";
import { FilterComponent } from "../../components/common/FilterComponent";

function HelpSupport() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [isDeletemodal, setIsDeletemodal] = useState(false);
  const [active, setActive] = useState(false);
  const [table_row_vendor, setTable_row_vendor] = useState({
    issueStatus: "pending",
    type: "vendor",
    openIssues: [],
    resolvedIssues: [],
  });
  const [table_row_customer, setTable_row_customer] = useState({
    issueStatus: "pending",
    type: "buyer",
    openIssues: [],
    resolvedIssues: [],
  });

  const [isViewModel, setIsViewModel] = useState(false);
  const [ismodal, setIsmodal] = useState(false);
  const [iseditmodal, setIseditmodal] = useState(false);
  const [isChangeStatusModel, setIsChangeStatusModel] = useState(false);
  const [user, setuser] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userData") ?? {});
  const [changeStatusTo, setChangeStatusTo] = useState({});

  const customStyles = {
    cells: {
      style: {
        marginRight: "20px", // Right margin add kar rahe hain
      },
    },
  };

  const columns = () => {
    return [
      // {
      //   name: " ID",
      //   selector: (row) => row.id,
      //   sortable: true,
      //   compact: true,
      // },

      {
        name: "Buyer Name",
        selector: (row) => (row?.buyer ? row?.buyer?.full_name : ""),
        sortable: true,
        compact: true,
        maxWidth: "220px",
      },
      {
        name: "Buyer Email",
        selector: (row) => (row?.buyer ? row?.buyer?.email : ""),
        sortable: true,
        compact: true,
        maxWidth: "220px",
      },
      {
        name: "Buyer Phone",
        selector: (row) => (row?.buyer ? row?.buyer?.phone : ""),
        sortable: true,
        compact: true,
        maxWidth: "220px",
      },

      {
        name: "subject",
        selector: (row) => row?.subject,
        sortable: true,
        compact: true,
        maxWidth: "300px",
      },
      {
        name: "description",
        selector: (row) => row?.description,
        sortable: true,
        compact: true,
        maxWidth: "350px",
      },
      {
        name: "Status",
        selector: (row) => {},
        sortable: true,
        compact: true,
        maxWidth: "150px",
        cell: (row) => (
          <>
            {row.status === "pending" && (
              <button
                type="button"
                className="btn btn-warning text-white"
                onClick={() => {
                  setIsChangeStatusModel(true);
                  setChangeStatusTo({
                    id: row?.id,
                    modelHeading: "Change Issue Status",
                    status: row.status === "pending" ? "resolved" : "",
                    apiParameters: {
                      status: row.status === "pending" ? "resolved " : "",
                    },
                  });
                }}
              >
                Pending
              </button>
            )}
            {row.status === "resolved" && (
              <button type="button" className="btn btn-success text-white">
                Resolved
              </button>
            )}
          </>
        ),
      },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        compact: true,
        cell: (row) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            {userData?.is_vendor ? (
              <button
                onClick={() => {
                  setIseditmodal(true);
                  reset({
                    ...row,
                  });
                  setValue("type", "edit");
                }}
                type="button"
                className="btn btn-outline-secondary"
              >
                <i className="icofont-edit text-success"></i>
              </button>
            ) : (
              <></>
            )}
            <button
              type="button"
              onClick={() => {
                setuser(row);
                setIsViewModel(true);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-eye-alt"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
          </div>
        ),
      },
    ];
  };
  const vendor_columns = () => {
    let vendor = [
      // {
      //   name: " ID",
      //   selector: (row) => row.id,
      //   sortable: true,
      //   compact: true,
      // },
      {
        name: "subject",
        selector: (row) => row?.subject,
        sortable: true,
        compact: true,
        maxWidth: "220px",
      },
      {
        name: "description",
        selector: (row) => row?.description,
        sortable: true,
        compact: true,
        maxWidth: "350px",
      },
      {
        name: "Status",
        selector: (row) => {},
        sortable: true,
        compact: true,
        maxWidth: "220px",
        cell: (row) => (
          <>
            {row.status === "pending" && (
              <button
                type="button"
                className="btn btn-warning text-white"
                style={{ cursor: userData?.is_vendor ? "auto" : "pointer" }}
                onClick={() => {
                  if (userData?.is_superuser) {
                    setIsChangeStatusModel(true);
                    setChangeStatusTo({
                      id: row?.id,
                      modelHeading: "Change Issue Status",
                      status: row.status === "pending" ? "resolved" : "",
                      apiParameters: {
                        status: row.status === "pending" ? "resolved " : "",
                      },
                    });
                  }
                }}
              >
                Pending
              </button>
            )}
            {row.status === "resolved" && (
              <button
                type="button"
                className="btn btn-success text-white"
                style={{ cursor: userData?.is_vendor ? "auto" : "pointer" }}
              >
                Resolved
              </button>
            )}
          </>
        ),
      },
      {
        name: "ACTION",
        selector: (row) => {},
        sortable: true,
        compact: true,
        cell: (row) => (
          <div
            className="btn-group"
            role="group"
            aria-label="Basic outlined example"
          >
            {userData?.is_vendor ? (
              <button
                onClick={() => {
                  setIseditmodal(true);
                  reset({
                    ...row,
                  });
                  setValue("type", "edit");
                }}
                type="button"
                className="btn btn-outline-secondary"
              >
                <i className="icofont-edit text-success"></i>
              </button>
            ) : (
              <></>
            )}
            <button
              type="button"
              onClick={() => {
                setuser(row);
                setIsViewModel(true);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-eye-alt"></i>
            </button>
            <button
              type="button"
              onClick={() => {
                setIsDeletemodal(true);
                setuser(row);
              }}
              className="btn btn-outline-secondary deleterow"
            >
              <i className="icofont-ui-delete text-danger"></i>
            </button>
          </div>
        ),
      },
    ];
    if (userData && userData?.is_superuser) {
      vendor = [
        {
          name: "Seller Name",
          selector: (row) => (row?.seller ? row?.seller?.full_name : ""),
          sortable: true,
          compact: true,
          maxWidth: "220px",
        },
        {
          name: "Seller Email",
          selector: (row) => (row?.seller ? row?.seller?.email : ""),
          sortable: true,
          compact: true,
          maxWidth: "220px",
        },
        {
          name: "Seller Phone",
          selector: (row) => (row?.seller ? row?.seller?.phone : ""),
          sortable: true,
          compact: true,
          maxWidth: "220px",
        },
        ...vendor,
      ];
    }

    return vendor;
  };

  const onDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/report-ASk/${user?.id}/`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 204) {
        toast.success("Issue deleted successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsDeletemodal();
      getHelpSupportList();
    }
  };

  async function getHelpSupportList() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/report-ASk/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      // Handle response if necessary
      const { data } = await response.json();
      // setTable_row(data);
      let user = JSON?.parse(localStorage?.getItem("userData")) || {};
      if (user && user?.is_superuser) {
        const help_customer = data.filter(
          (item) => item?.api_type === "help" && item?.user_type === "buyer"
        );
        const help_vendor = data.filter(
          (item) => item?.api_type === "help" && item?.user_type === "seller"
        );
        // setTable_row(help_customer);
        setTable_row_customer((prev) => ({
          ...prev,
          type: "buyer",
          openIssues: help_customer?.filter(
            (item) => item?.status === "pending"
          ),
          resolvedIssues: help_customer?.filter(
            (item) => item?.status === "resolved"
          ),
        }));
        setTable_row_vendor((prev) => ({
          ...prev,
          status: false,
          type: "vendor",
          openIssues: help_vendor?.filter((item) => item?.status === "pending"),
          resolvedIssues: help_vendor?.filter(
            (item) => item?.status === "resolved"
          ),
        }));
      } else {
        setActive(false);
        setTable_row_vendor((prev) => ({
          ...prev,
          status: false,
          type: "vendor",
          openIssues: data.filter(
            (item) =>
              item?.seller?.id == user.id &&
              item?.api_type === "help" &&
              item?.status === "pending"
          ),
          resolvedIssues: data.filter(
            (item) =>
              item?.seller?.id == user.id &&
              item?.api_type === "help" &&
              item?.status === "resolved"
          ),
        }));
      }
      // ...
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  }

  useEffect(() => {
    getHelpSupportList();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("subject", data.subject);
      formData.append("description", data.description);
      formData.append("seller", userData?.id);
      formData.append("status", "pending");
      formData.append("api_type", "help");
      formData.append("user_type", "seller");

      
      const apiEndPoint = data.type === "add" ? 'api/Help_Support/report-Form' : 'api/Help_Support/report-ASk'

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/${apiEndPoint}/${
          data.type === "add" ? "" : data.id + "/"
        }`,
        {
          method: data.type === "add" ? "POST" : "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData.msg);
        if (response.status === 201) {
          setTimeout(() => {
            toast.success("Issue created successfully");
            setIsmodal(false);
          }, 500);
        }
        if (response.status === 200) {
          toast.success("Issue updated successfully");
          setIseditmodal(false);
        }
        getHelpSupportList();
      } else {
        const data = await response.json();
        toast.error(Object.values(data).join(", ") || "Something went wrong");
        console.error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Something went wrong");
      console.error(error, "Error submitting form:");
    } finally {
      setIsmodal(false);
    }
  };

  const onStatusSubmit = async () => {
    try {
      const formData = new FormData();
      Object.entries(changeStatusTo.apiParameters)?.forEach(([key, value]) => {
        formData.append(key, value.trim());
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/Help_Support/report-ASk/${changeStatusTo?.id}/`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit the data. Please try again.");
      }

      if (response.status === 200) {
        toast.success("Report status updated Successfully");
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    } finally {
      setIsChangeStatusModel(false);
      getHelpSupportList();
    }
  };

  // Filtering Product based on Title
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredOpenIssuesCustomer = table_row_customer.openIssues.filter(
    (item) => {
      return (
        item?.subject &&
        item?.subject.toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );
  const filteredResolvedIssuesCustomer =
    table_row_customer.resolvedIssues.filter((item) => {
      return (
        item?.subject &&
        item?.subject.toLowerCase().includes(filterText.toLowerCase())
      );
    });
  const filteredOpenIssuesVendor = table_row_vendor.openIssues.filter(
    (item) => {
      return (
        item?.subject &&
        item?.subject.toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );
  const filteredResolvedIssuesVendor = table_row_vendor.resolvedIssues.filter(
    (item) => {
      return (
        item?.subject &&
        item?.subject.toLowerCase().includes(filterText.toLowerCase())
      );
    }
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <div className="body d-flex">
      <div className="container-xxl mt-3">
        <PageHeader1
          pagetitle="Help and Support Information"
          modalbutton={() => {
            return (
              <div className="col-auto d-flex w-sm-100">
                {userData?.is_vendor ? (
                  <button
                    type="button"
                    onClick={() => {
                      reset({});
                      setIsmodal(true);
                      setValue("type", "add");
                    }}
                    className="btn btn-primary btn-set-task w-sm-100"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Add Issue
                  </button>
                ) : (
                  <></>
                )}
              </div>
            );
          }}
        />
        {userData && userData?.is_superuser ? (
          <div
            className={`d-flex align-items-center justify-content-between ${st.tabsTable}`}
          >
            <ul>
              {" "}
              <li
                className={`${
                  !active &&
                  table_row_vendor?.type === "vendor" &&
                  table_row_vendor?.issueStatus === "pending"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setActive(false);
                  setTable_row_vendor((prev) => ({
                    ...prev,
                    issueStatus: "pending",
                  }));
                }}
              >
                Vendors Open
              </li>
              <li
                className={`${
                  !active &&
                  table_row_vendor?.type === "vendor" &&
                  table_row_vendor?.issueStatus === "resolved"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setActive(false);
                  setTable_row_vendor((prev) => ({
                    ...prev,
                    issueStatus: "resolved",
                  }));
                }}
              >
                Vendors Resolved
              </li>
              <li
                className={`${
                  active &&
                  table_row_customer?.type === "buyer" &&
                  table_row_customer?.issueStatus === "pending"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setActive(true);
                  setTable_row_customer((prev) => ({
                    ...prev,
                    issueStatus: "pending",
                  }));
                }}
              >
                Buyers Open
              </li>
              <li
                className={`${
                  active &&
                  table_row_customer?.type === "buyer" &&
                  table_row_customer?.issueStatus === "resolved"
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setActive(true);
                  setTable_row_customer((prev) => ({
                    ...prev,
                    issueStatus: "resolved",
                  }));
                }}
              >
                Buyers Resolved
              </li>
            </ul>
            <ul>
              <li onClick={getHelpSupportList}>
                <MdOutlineRefresh />
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}

        {active ? (
          <div className="row clearfix g-3">
            <div className="col-sm-12 mt-2">
              <div className="card">
                <div className="card-body">
                  <div
                    id="myProjectTable_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {table_row_customer?.issueStatus === "pending" && (
                          <DataTable
                            columns={columns()}
                            // data={table_row_customer}
                            defaultSortField="title"
                            pagination
                            selectableRows={false}
                            className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                            highlightOnHover={true}
                            data={filteredOpenIssuesCustomer}
                            subHeader
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                            customStyles={customStyles}
                          />
                        )}
                        {table_row_customer?.issueStatus === "resolved" && (
                          <DataTable
                            columns={columns()}
                            // data={table_row_customer}
                            defaultSortField="title"
                            pagination
                            selectableRows={false}
                            className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                            highlightOnHover={true}
                            data={filteredResolvedIssuesCustomer}
                            subHeader
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                            customStyles={customStyles}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row clearfix g-3">
            <div className="col-sm-12">
              <div className="card mb-3">
                <div className="card-body">
                  <div
                    id="myProjectTable_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {table_row_vendor?.issueStatus === "pending" && (
                          <DataTable
                            columns={vendor_columns()}
                            // data={table_row_vendor}
                            defaultSortField="title"
                            pagination
                            selectableRows={false}
                            className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                            highlightOnHover={true}
                            data={filteredOpenIssuesVendor}
                            subHeader
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                            customStyles={customStyles}
                          />
                        )}
                        {table_row_vendor?.issueStatus === "resolved" && (
                          <DataTable
                            columns={vendor_columns()}
                            // data={table_row_vendor}
                            defaultSortField="title"
                            pagination
                            selectableRows={false}
                            className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                            highlightOnHover={true}
                            data={filteredResolvedIssuesVendor}
                            subHeader
                            paginationResetDefaultPage={resetPaginationToggle}
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                            customStyles={customStyles}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        show={iseditmodal}
        onHide={() => {
          setIseditmodal(false);
        }}
        className=""
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expeditLabel">
            {" "}
            Edit Issue
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    id="subject"
                    {...register("subject", { required: true })}
                  />
                  {errors.subject && (
                    <span style={{ color: "red" }}>Subject is required</span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    id="description"
                    {...register("description", { required: true })}
                  />
                  {errors.description && (
                    <span style={{ color: "red" }}>
                      Description is required
                    </span>
                  )}
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIseditmodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isViewModel}
        onHide={() => {
          setIsViewModel(false);
          setuser(null);
        }}
        className=""
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expeditLabel">
            {" "}
            View Issue
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <div className="row g-3 mb-3">
              {userData?.is_superuser && (
                <>
                  {" "}
                  <div className="col-sm-12">
                    <label
                      htmlFor={
                        user?.user_type === "seller" ? "seller" : "buyer"
                      }
                      className="form-label"
                    >
                      {user?.user_type === "seller"
                        ? "Seller Name"
                        : "Buyer Name"}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${user?.user_type === "seller" ? "seller" : "buyer"}
`}
                      value={
                        user?.user_type === "seller"
                          ? user?.seller?.full_name
                          : "Buyer"
                      }
                      disabled
                    />
                  </div>
                  <div className="col-sm-12">
                    <label
                      htmlFor={
                        user?.user_type === "seller"
                          ? "seller_email"
                          : "buyer_email"
                      }
                      className="form-label"
                    >
                      {user?.user_type === "seller"
                        ? "Seller Email"
                        : "Buyer Email"}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${
                        user?.user_type === "seller"
                          ? "seller_email"
                          : "buyer_email"
                      }
`}
                      value={
                        user?.user_type === "seller"
                          ? user?.seller?.email
                          : "Buyer"
                      }
                      disabled
                    />
                  </div>
                  <div className="col-sm-12">
                    <label
                      htmlFor={
                        user?.user_type === "seller"
                          ? "seller_phone"
                          : "buyer_phone"
                      }
                      className="form-label"
                    >
                      {user?.user_type === "seller"
                        ? "Seller Phone"
                        : "Buyer Phone"}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id={`${
                        user?.user_type === "seller"
                          ? "seller_phone"
                          : "buyer_phone"
                      }
`}
                      value={
                        user?.user_type === "seller"
                          ? user?.seller?.phone
                          : "Buyer"
                      }
                      disabled
                    />
                  </div>
                </>
              )}

              <div className="col-sm-12">
                <label htmlFor="subject" className="form-label">
                  Subject
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  value={user?.subject}
                  disabled
                />
              </div>
              <div className="col-sm-12">
                <label htmlFor="descripiton" className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="description"
                  value={user?.description}
                  disabled
                />
              </div>
            </div>
            <div className={`${st.modalButtonBottom}`}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setIsViewModel(false);
                  setuser(null);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={ismodal}
        onHide={() => {
          setIsmodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Add Issue
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3 mb-3">
                <div className="col-sm-12">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Subject"
                    id="subject"
                    {...register("subject", {
                      required: "Subject is required",
                    })}
                  />
                  {errors.subject && (
                    <span style={{ color: "red" }}>
                      {errors.subject.message}
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    id="description"
                    {...register("description", {
                      required: "Description is required",
                    })}
                  />
                  {errors.description && (
                    <span style={{ color: "red" }}>
                      {errors.description.message}
                    </span>
                  )}
                </div>
              </div>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsmodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={isDeletemodal}
        onHide={() => {
          setIsDeletemodal(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            Delete Isuue
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to delete ?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsDeletemodal(false);
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onDeleteSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={isChangeStatusModel}
        onHide={() => {
          setIsChangeStatusModel(false);
        }}
        style={{ display: "block" }}
      >
        <Modal.Header className="modal-header" closeButton>
          <h5 className="modal-title  fw-bold" id="expaddLabel">
            {changeStatusTo?.modelHeading}
          </h5>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="deadline-form">
            <form>
              <h4
                style={{
                  textAlign: "center",
                  fontSize: "22px",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}
              >
                Are you sure you want to change the status to{" "}
                {changeStatusTo.status}?
              </h4>
              <div className={`${st.modalButtonBottom}`}>
                <button
                  onClick={() => {
                    setIsChangeStatusModel(false);
                    setChangeStatusTo({});
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Cancel
                </button>
                <button
                  onClick={onStatusSubmit}
                  type="button"
                  className="btn btn-primary"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default HelpSupport;
